"use client";

import { HttpLink } from "@apollo/client";
import {
  ApolloNextAppProvider,
  ApolloClient,
  InMemoryCache,
} from "@apollo/experimental-nextjs-app-support";
import { getGraphqlProxyUrl } from "../lib/getGraphqlProxyUrl";
import { usePathname } from "next/navigation";
import { localeCodeFromUrl } from "@/utils";

function createApolloClient(pathname?: string, culture?: string | null) {
  const localeCode =
    pathname === "/preview/"
      ? culture
      : pathname
      ? localeCodeFromUrl(pathname)
      : undefined;

  const httpLink = new HttpLink({
    uri: pathname
      ? getGraphqlProxyUrl(`?localesCode=${localeCode}`)
      : getGraphqlProxyUrl(),
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink,
  });
}

type ApolloWrapperProps = {
  culture: string;
} & React.PropsWithChildren;

export function ApolloWrapper({ children, culture }: ApolloWrapperProps) {
  const pathname = usePathname();

  if (pathname === "/favicon.ico") {
    return;
  }

  const makeClient = () => createApolloClient(pathname, culture);

  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
}
